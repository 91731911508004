<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-data-table
                :headers="moteFelter"
                :items="moter"
                :loading="henter"
                loading-text="Henter møter..."
                no-data-text="Ingen møter."
                :items-per-page="-1"
                must-sort
                hide-default-footer
            >
                <template v-slot:header.rediger> </template>

                <template v-slot:item.date="{ item }">
                    <span v-html="item.aktivitet.start.format('DD.MM.YYYY')"></span>
                </template>

                <template v-slot:item.time="{ item }">
                    <span v-html="item.aktivitet.start.format('HH.mm') + ' - ' + item.aktivitet.slutt.format('HH.mm')"></span>
                </template>

                <template v-slot:item.moterom="{ item }">
                    <span v-html="item.aktivitet.moterom ? item.aktivitet.moterom.navn : ''"></span>
                </template>

                <template v-slot:item.rediger="{ item }">
                    <div class="d-flex justify-center">
                        <router-link :to="'/sorg/gruppemote/' + item.aktivitet.id" class="text-decoration-none">
                            <v-icon small>mdi-card-text</v-icon>
                        </router-link>
                        <router-link :to="'/sorg/gruppemote/' + item.aktivitet.id + '/rediger'" class="ml-2 text-decoration-none">
                            <v-icon small>mdi-pencil</v-icon>
                        </router-link>
                        <v-icon small v-on:click="deleteMeeting(item)" class="ml-2 text-decoration-none">mdi-delete</v-icon>
                    </div>
                </template>

                <template v-slot:foot>
                    <tfoot>
                        <tr>
                            <td colspan="4" style="height: auto">
                                <div class="pt-4 d-flex align-center">
                                    <v-form ref="form" v-model="valid" lazy-validation class="d-flex">
                                        <div class="pr-6">
                                            <date-field v-model="form.dato" :rules="rules.dato" label="Dag" class="mr-4"></date-field>
                                        </div>
                                        <div class="pr-6">
                                            <time-field v-model="form.start" :rules="rules.start" label="Fra"></time-field>
                                        </div>
                                        <div class="pr-6">
                                            <time-field v-model="form.slutt" :rules="rules.slutt" label="Til"></time-field>
                                        </div>
                                        <div class="pr-6">
                                            <v-select
                                                v-model="form.moteromId"
                                                :items="moterom"
                                                :loading="henterMoterom"
                                                :rules="rules.moterom"
                                                item-value="id"
                                                item-text="navn"
                                                label="Møterom"
                                                no-data-text="Ingen ledige møterom"
                                            ></v-select>
                                        </div>
                                        <div class="pr-6">
                                            <v-btn outlined class="mt-4 ml-2" v-on:click="addMeeting"> Opprett </v-btn>
                                        </div>
                                    </v-form>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </template>
            </v-data-table>
            <alert ref="alert"></alert>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';
import TimeField from '@/vendor/noop/components/TimeField.vue';
import Alert from '@/vendor/noop/components/Alert.vue';

export default {
    name: 'GriefGroupTabmeetings',
    components: { DateField, TimeField, Alert },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            gruppe: {},

            visAvmeldte: false,

            henter: true,
            moter: [],

            valid: true,
            form: {
                dato: null,
                start: '00:00',
                slutt: '00:00',
                moteromId: null,
            },

            henterMoterom: false,
            moterom: [],

            moteFelter: [
                { text: 'Dag', value: 'date', sortable: false },
                { text: 'Tidspunkt', value: 'time', sortable: false },
                { text: 'Møterom', value: 'moterom', sortable: false },
                { text: '', value: 'rediger', sortable: false, width: '130px' },
            ],

            rules: {
                dato: [(v) => !!v || 'Må oppgis'],
                start: [(v) => !!v || 'Må oppgis'],
                slutt: [(v) => !!v || 'Må oppgis'],
                moterom: [(v) => !!v || 'Må oppgis'],
            },
        };
    },
    filters: {
        format: function (value, format) {
            return value.format(format);
        },
    },
    watch: {
        form: {
            handler: function () {
                window.setTimeout(() => {
                    this.updateAvailableRooms();
                }, 250);
            },
            deep: true,
        },
    },

    /**
     * created
     */
    created: async function () {
        this.gruppe = this.value;

        /*const response = await this.request({
            method: 'get',
            url: '/moterom',
        });
        if (response && Array.isArray(response)) {
            this.moterom = response;
        }*/

        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatGriefGroup', 'formatActivity', 'formatPerson']),
        ...mapActions(['snackbar', 'confirm']),

        /**
         * update
         */
        update: async function (gruppe) {
            this.henter = true;

            if (gruppe) {
                this.gruppe = await this.formatGriefGroup(gruppe);
                this.$emit('input', gruppe);
            }

            this.moter = [];

            if (this.gruppe && this.gruppe.moter) {
                const aktiviteter = await this.request({
                    method: 'get',
                    url: '/aktivitet/aktivitet-type/SORGGRUPPE',
                });
                if (aktiviteter) {
                    for (const aktivitet of aktiviteter) {
                        for (const mote of this.gruppe.moter) {
                            if (mote.id == aktivitet.id) {
                                mote.aktivitet = await this.formatActivity(aktivitet);
                                this.moter.push(mote);
                                break;
                            }
                        }
                    }
                }
            }

            // personer
            let response = await this.request({
                method: 'get',
                url: '/person/bruker/sorg',
            });
            if (response && typeof Array.isArray(response)) {
                this.alleDeltakere = [];
                for (const person of response) {
                    this.alleDeltakere.push(await this.formatPerson(person));
                }
            }

            this.henter = false;
        },

        /**
         * addMeeting
         */
        addMeeting: async function () {
            if (this.$refs.form.validate()) {
                if (this.form.moteromId) {
                    await this.updateAvailableRooms();
                    console.log(this.moterom);
                    let available = false;
                    for (const moterom of this.moterom) {
                        if (moterom.id == this.form.moteromId) {
                            available = true;
                            break;
                        }
                    }
                    if (!available) {
                        this.$refs.alert.open('Feil', 'Møterommet er ikke ledig i det aktuelle tidsrommet. Velg et annet rom.');
                        return;
                    }
                }

                const data = new FormData();
                data.append('start', this.form.dato.format('DD.MM.YYYY') + ' ' + this.form.start);
                data.append('slutt', this.form.dato.format('DD.MM.YYYY') + ' ' + this.form.slutt);
                if (this.form.moteromId) {
                    data.append('moteromId', this.form.moteromId);
                }

                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe/' + this.gruppe.id + '/mote',
                    data,
                });
                if (!response) {
                    console.log('TODO: error');
                } else {
                    this.update(response);
                    this.snackbar('Opprettet');
                }
            }
        },

        /**
         * deleteMeeting
         */
        deleteMeeting: async function (item) {
            if (await this.confirm({ title: 'Slette?', message: 'Sikker på at du vil slette møtet?' })) {
                const response = await this.request({
                    method: 'delete',
                    url: '/sorggruppe/' + this.gruppe.id + '/mote/' + item.id,
                });
                if (!response) {
                    console.log('TODO: error');
                } else {
                    this.update(response);
                    this.snackbar('Slettet');
                }
            }
        },

        /**
         * updateAvailableRooms
         */
        updateAvailableRooms: async function () {
            if (this.form.dato && this.form.start && this.form.slutt) {
                if (!this.henterMoterom) {
                    this.henterMoterom = true;

                    const start = this.form.dato.format('DD.MM.YYYY') + ' ' + this.form.start;
                    const end = this.form.dato.format('DD.MM.YYYY') + ' ' + this.form.slutt;

                    const response = await this.request({
                        method: 'get',
                        url: '/moterom/ledig',
                        params: {
                            fra: start,
                            til: end,
                        },
                    });

                    if (response && Array.isArray(response)) {
                        this.moterom = response;
                    } else {
                        this.moterom = [];
                    }

                    this.henterMoterom = false;
                }
            }
        },
    },
};
</script>
