<template>
    <div>
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="80">
                    <v-toolbar flat floating>
                        <v-toolbar-title class="mr-4 text-h4" style="margin-left: -6px"> Gruppemøte </v-toolbar-title>
                        <v-btn fab text small color="grey darken-2" @click="prev">
                            <v-icon small> mdi-chevron-left </v-icon>
                        </v-btn>

                        <v-select
                            v-model="type"
                            :items="types"
                            item-value="id"
                            item-text="label"
                            dense
                            outlined
                            hide-details
                            class="mr-4"
                            style="width: 130px"
                        ></v-select>

                        <v-select
                            v-if="type == 'week'"
                            v-model="week"
                            :items="weeks"
                            item-value="label"
                            item-text="label"
                            dense
                            outlined
                            hide-details
                            class="mr-4"
                            style="width: 130px"
                        ></v-select>

                        <v-select
                            v-if="type == 'month'"
                            v-model="month"
                            :items="months"
                            item-value="nr"
                            item-text="label"
                            dense
                            outlined
                            hide-details
                            class="mr-4"
                            style="width: 130px"
                        ></v-select>

                        <v-select
                            v-model="year"
                            :items="years"
                            item-value="nr"
                            item-text="label"
                            dense
                            outlined
                            hide-details
                            class="mr-4"
                            style="width: 130px"
                        ></v-select>

                        <v-btn fab text small color="grey darken-2" @click="next">
                            <v-icon small> mdi-chevron-right </v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-sheet>
                <v-sheet height="calc(100vh - 260px)">
                    <v-calendar
                        ref="calendar"
                        v-model="focus"
                        :type="type"
                        :events="events"
                        mode="stack"
                        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="getEvents"
                    >
                        <template v-slot:event="{ event }">
                            <div class="pl-1">
                                <span class="v-event-summary">
                                    <strong>{{ event.name }}</strong>
                                    <br />
                                    {{ event.when }}<br />
                                    {{ event.where }}
                                </span>
                            </div>
                        </template>
                    </v-calendar>
                    <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
                </v-sheet>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="480">
            <v-card>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-btn icon v-if="canEdit" v-on:click="editEvent">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon v-on:click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-list dense class="v-list--dense-icons">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon small color="primary">mdi-card</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">
                                {{ aktivitet.tittel }}
                            </v-list-item-title>
                            <div class="body-1 grey--text text--darken-2" style="margin-top: 4px">
                                {{ aktivitet.start | format('dddd D. MMMM [kl. ] HH:mm') }}
                                <template v-if="aktivitet.slutt"> - {{ aktivitet.slutt | format('HH:mm') }} </template>
                            </div>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="aktivitet.moterom">
                        <v-list-item-icon>
                            <v-icon small color="primary">mdi-map-marker</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            {{ aktivitet.moterom.navn }}
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="sorgGruppe && sorgGruppe.ledere && sorgGruppe.ledere.length > 0">
                        <v-list-item-icon>
                            <v-icon small color="primary">mdi-account-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="text-caption">Gruppeledere</div>
                            <div v-for="(leder, index) in sorgGruppe.ledere" v-bind:key="index">
                                <template v-if="!leder.fjernet">
                                    <div class="text-body-1">{{ leder.person.fulltNavn }}</div>
                                    <div class="text-body-2">
                                        <template v-if="leder.person.mobil">{{ leder.person.mobil }} </template>
                                        <template v-if="leder.person.mobil && leder.person.epost"> - </template>
                                        <template v-if="leder.person.epost">
                                            <a :href="'mailto:' + leder.person.epost">{{ leder.person.epost }}</a>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="canEdit && aktivitet.deltakere && aktivitet.deltakere.length > 0">
                        <v-list-item-icon>
                            <v-icon small color="primary">mdi-account-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="text-caption">Deltakere</div>
                            <div v-for="(deltaker, index) in aktivitet.deltakere" v-bind:key="index">
                                <template v-if="!deltaker.fjernet">
                                    {{ deltaker.person.fulltNavn }}
                                </template>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <br />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Calendar',
    data() {
        const today = moment();

        const weeks = [];
        const months = [];
        const years = [];

        for (let i = 1; i <= 52; i++) {
            weeks.push({ date: moment().day('Monday').week(i), label: i });
        }

        for (let i = 0; i <= 11; i++) {
            const date = moment().day('Monday').month(i);
            let month = date.format('MMMM');
            month = month.charAt(0).toUpperCase() + month.slice(1);
            months.push({ nr: i, label: month });
        }

        for (let y = 2022; y <= moment().year() + 1; y++) {
            years.push({ nr: y, label: y });
        }

        return {
            type: 'week',

            loading: false,
            aktiviteter: [],

            focus: today.format('YYYY-MM-DD'),
            start: today.startOf('week'),

            week: today.week(),
            month: today.month(),
            year: today.year(),
            currentYear: today.year(),

            events: [],

            dialog: false,
            aktivitet: [],
            sorgGruppe: null,

            types: [
                { id: 'day', label: 'Dag' },
                { id: 'week', label: 'Uke' },
                { id: 'month', label: 'Måned' },
            ],
            weeks: weeks,
            months: months,
            years: years,
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole']),

        canEdit: function () {
            let canEdit = false;
            if (this.sorgGruppe) {
                if (this.hasRole('ANSATT')) {
                    canEdit = true;
                } else if (this.hasRole('FRIVILLIG_SORG')) {
                    for (const leder of this.sorgGruppe.ledere) {
                        if (!leder.fjernet) {
                            if (leder.person.id == this.user.id) {
                                canEdit = true;
                                break;
                            }
                        }
                    }
                }
            }

            return canEdit;
        },
    },
    watch: {
        focus: function (value) {
            const focus = moment(value, 'YYYY-MM-DD');
            this.week = focus.week();
            this.month = focus.month();
            this.year = focus.year();
            console.log(focus.format('YYYY-MM.-DD'));
        },
        type: function (value) {
            if (value == 'month') {
                //this.start = this.start.startOf('month');
            }
        },
        week: function (value) {
            const focus = moment(this.focus, 'YYYY-MM-DD');
            if (focus.week() != value) {
                this.focus = moment().day('Monday').week(value).format('YYYY-MM-DD');
            }
        },
        month: function (value) {
            const focus = moment(this.focus, 'YYYY-MM-DD');
            if (focus.month() != value) {
                this.focus = moment().date(1).month(value).format('YYYY-MM-DD');
            }
        },
        year: function (value) {
            const focus = moment(this.focus, 'YYYY-MM-DD');
            if (focus.year() != value) {
                if (this.type == 'week') {
                    this.focus = moment().date(1).year(value).week(this.week).format('YYYY-MM-DD');
                } else if (this.type == 'month') {
                    this.focus = moment().date(1).year(value).month(this.month).format('YYYY-MM-DD');
                }
            }
        },
    },
    filters: {
        format(value, format) {
            if (value) {
                return value.format(format);
            }
            return '';
        },
    },
    /**
     * created
     */
    created: async function () {},
    methods: {
        ...mapActions('api', ['request', 'formatActivity', 'formatGriefGroup']),

        /**
         * getEvents
         */
        getEvents: async function ({ start, end }) {
            start = moment(start.date, 'YYYY-MM-DD');
            end = moment(end.date, 'YYYY-MM-DD').add(1, 'day');

            if (!this.loading) {
                this.loading = true;

                let aktiviteter = [];
                const response = await this.request({
                    method: 'get',
                    url: '/aktivitet',
                    params: {
                        fra: start.format('DD.MM.YYYY HH:mm'),
                        til: end.format('DD.MM.YYYY HH:mm'),
                        eksluderSystemTyper: false,
                    },
                });
                if (response && Array.isArray(response)) {
                    aktiviteter = response;
                }

                const events = [];
                if (aktiviteter) {
                    this.aktiviteter = [];
                    for (let aktivitet of aktiviteter) {
                        aktivitet = await this.formatActivity(aktivitet);
                        this.aktiviteter.push(aktivitet);

                        let where = '';
                        if (aktivitet.moterom && aktivitet.moterom.aktiv) {
                            where = aktivitet.moterom.navn;
                        }
                        let color = 'grey';
                        if (aktivitet.type.id == 'SORGGRUPPE') {
                            color = 'primary';
                        }

                        events.push({
                            id: aktivitet.id,
                            start: aktivitet.start.toDate(),
                            end: aktivitet.slutt.toDate(),
                            name: aktivitet.tittel,
                            timed: true,
                            color: color,
                            event: aktivitet,
                            when: aktivitet.start.format('HH:mm') + ' - ' + aktivitet.slutt.format('HH:mm'),
                            where: where,
                        });
                    }
                }

                this.events = events;
                this.loading = false;

                this.$refs.calendar.scrollToTime('16:00');
            }
        },

        /**
         * showEvent
         */
        showEvent: async function ({ nativeEvent, event }) {
            if (event.event && event.event.type.id == 'SORGGRUPPE') {
                nativeEvent.stopPropagation();
                this.aktivitet = event.event;
                this.sorgGruppe = null;

                const sorgGruppeId = this.aktivitet.referanseId;
                if (sorgGruppeId) {
                    const response = await this.request({
                        method: 'get',
                        url: '/sorggruppe/' + sorgGruppeId,
                    });
                    if (response && response.id) {
                        this.sorgGruppe = await this.formatGriefGroup(response);
                    }
                }

                if (this.canEdit) {
                    if (sorgGruppeId) {
                        const response = await this.request({
                            method: 'get',
                            url: '/aktivitet/' + this.aktivitet.id,
                        });
                        if (response && response.id) {
                            this.aktivitet = await this.formatActivity(response);
                        }
                    }
                }

                this.dialog = true;
            }
        },
        /**
         * editEvent
         */
        editEvent: function () {
            this.$router.push({ name: '/sorg/gruppemote/rediger', params: { id: this.aktivitet.id } });
        },

        /**
         * viewDay
         */
        viewDay({ date }) {
            this.focus = date;
            this.type = 'day';
        },

        /**
         * prev
         */
        prev() {
            this.$refs.calendar.prev();
        },

        /**
         * next
         */
        next() {
            this.$refs.calendar.next();
        },
    },
};
</script>
<style>
.v-list--dense-icons .v-list-item__icon {
    margin-right: 6px !important;
}
</style>
